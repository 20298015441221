<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">

            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="add_sprint()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

        </v-card>
        
        <v-form v-model="valid">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>

                  <v-text-field
                    v-model="name"
                    label="Nazwa etapu"
                    color="primary"
                  ></v-text-field>

                  <v-text-field
                    v-model="hours"
                    label="Przewidywany czas na etap (godziny)"
                    type="number"
                    color="primary"
                  ></v-text-field>

                  <v-select
                    v-model="selected_users"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    class="classic_select"
                    chips
                    label="Przypisani użytkownicy"
                    multiple
                  ></v-select>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista projektów',
        disabled: false,
        to: '/projekty',
      },
      {
        text: 'Projekt',
        disabled: false,
        to: '/projekt',
      },
      {
        text: 'Nowy etap',
        disabled: true,
        to: '/projekt-nowy',
      },
    ],

    name: '',
    selected_users: [],
    users: [],

    nme: '',
    hours: '',
  }),
  methods: {
    add_sprint() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("project_id", this.id);
      formData.append("name", this.name);
      if(this.hours) formData.append("hours", this.hours);
      formData.append("status", 'Nowy');
      for(let index in this.selected_users){
        formData.append("assign_id["+index+"]", this.selected_users[index]);
      }

      this.$axios({url: this.$store.state.api +'/sprint', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/projekt/'+this.id);
          this.$store.commit("snackbar", {
            text: "Dodano etap",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
  },
  mounted(){
    this.id = this.$route.params.id;
    this.breadcrumb[2].to = '/projekt/'+this.id;

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/project/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.users = resp.data.users;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
</style>